$background-color: #eaedf9;
$transparent: transparent;

// G-Blue 1
$G-Blue1-100: #b6b0d0;
$G-Blue1-300: #634fc3;
$G-Blue1-400: #2372f2;
$G-Blue1-500: #120068;
$G-Blue1-700: #03123f;

// G-Blue 2
$G-Blue2-primary: #163aa6;
$G-Blue2: #317ffe;
$G-Blue2-light-lighting: #eff7ff;
$G-Blue2-light-hover: #dbdff0;
$G-Blue2-light-active: #b5bedf;
$G-Blue2-normal: #112c98;
$G-Blue2-normal-active: #d1e1fa;

// G-Blue 3
$G-Blue3-normal: #bfdffe;
$G-Blue3-light-hover: #dbeaf9;
$G-Blue3-light-active: #d0f0ef;

// G-Purple
$G-Purple-50: #f5f3ff;
$G-Purple-300: #7f5af8;

// G-Green
$G-Green-light: #ecfdf2;
$G-Green-light-active: #b1f5c8;
$G-Green-normal: #00ed9c;
$G-Green-normal-active: #12b749;
$G-Green-300: #12b749;
$G-Green-600: #009933;

// G-Yellow
$G-Orange: #ffaa00;
$G-Orange-light-50: #fffae0;
$G-Orange-light-active: #fbe1b0;
$G-Orange-normal: #f19e00;
$G-Orange-normal-active: #eb7911;
$G-Orange-dark: #b93600;

// G-White
$G-White: #ffffff;
$G-Light: #f3f4f8;
$G-Light-White: #fefefe;
$G-Light-Normal: #f7f6f9;
$G-Light-Normal-disabled: #b6b8c4;
$G-Light-Normal-hover: #e3e9ef;
$G-Light-Normal-active: #c6c5c7;
$G-Dark-active: #6f6f70;
$G-Light-Darker: #565657;
$G-White-Normal-hover: #d0f0ef;
$G-White-Normal: #f7f6f9;

// G-Red
$G-Red: #f10a0e;
$G-Red-lighting-org-white: #fbf3e5;
$G-Red-light: #feeeed;
$G-Red-light-active: #fdb5b0;
$G-Red-normal: #ff3f05;
$G-Red-normal-hover: #de4100;
$G-Red-dark: #d62600;

// G-Dark
$G-Dark: #05001a;
$G-Dark-2: #070021;

//P-tag
$G-GRAY-P-TAG: #565657;
$G-Dark-light-normal: #060022;
$G-Dark-light-hover: #dad9de;

$assets: '/assets';

$font_fellix: 'Fellix';

//new properties
$G-nav-bg: #f6f8f9; // spl:#00c8e1
$button-border-radius: '24px'; //spl: 0 for all buttons
$icon-button-border-radius: '8px'; //spl: 0 for icon buttons
$GK_box_shadow: 0 4px 12px 2px rgba(190, 190, 190, 0.25);

//#region Updated Colors
$gk-black: #03123f;
$gk-primary-8-percent: #eff5ff;
$gk-primary-25-percent: #cbdffe;
$gk-primary-disabled: #f5f7f9;
$gk-gray-blue: #e3e9ef;
$gk-green: #009933;
$gk-green-8-percent: #eefaf4;
$gk-green-25-percent: #c8efdb;
//#endregion Updated Colors
