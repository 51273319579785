@import 'src/styles/layout/variables';

.gk-elevation-1 {
  box-shadow: $GK_box_shadow !important;
}

// #region Padding Classes
.px-5px {
  padding-inline-start: 0.3125rem !important;
  padding-inline-end: 0.3125rem !important;
}

.pe-5px {
  padding-inline-end: 0.3125rem !important;
}

.ps-5px {
  padding-inline-start: 0.3125rem !important;
}

.py-5px {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.pt-5px {
  padding-top: 0.3125rem !important;
}

.pb-5px {
  padding-bottom: 0.3125rem !important;
}

.px-10px {
  padding-inline-start: 0.625rem !important;
  padding-inline-end: 0.625rem !important;
}

.pe-10px {
  padding-inline-end: 0.625rem !important;
}

.ps-10px {
  padding-inline-start: 0.625rem !important;
}

.py-10px {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.pt-10px {
  padding-top: 0.625rem !important;
}

.pb-10px {
  padding-bottom: 0.625rem !important;
}

.px-15px {
  padding-inline-start: 0.9375rem !important;
  padding-inline-end: 0.9375rem !important;
}

.pe-15px {
  padding-inline-end: 0.9375rem !important;
}

.ps-15px {
  padding-inline-start: 0.9375rem !important;
}

.py-15px {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.pt-15px {
  padding-top: 0.9375rem !important;
}

.pb-15px {
  padding-bottom: 0.9375rem !important;
}

.px-20px {
  padding-inline-start: 1.25rem !important;
  padding-inline-end: 1.25rem !important;
}

.pe-20px {
  padding-inline-end: 1.25rem !important;
}

.ps-20px {
  padding-inline-start: 1.25rem !important;
}

.py-20px {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.pt-20px {
  padding-top: 1.25rem !important;
}

.pb-20px {
  padding-bottom: 1.25rem !important;
}

.px-25px {
  padding-inline-start: 1.5625rem !important;
  padding-inline-end: 1.5625rem !important;
}

.pe-25px {
  padding-inline-end: 1.5625rem !important;
}

.ps-25px {
  padding-inline-start: 1.5625rem !important;
}

.py-25px {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.pt-25px {
  padding-top: 1.5625rem !important;
}

.pb-25px {
  padding-bottom: 1.5625rem !important;
}

.px-30px {
  padding-inline-start: 1.875rem !important;
  padding-inline-end: 1.875rem !important;
}

.pe-30px {
  padding-inline-end: 1.875rem !important;
}

.ps-30px {
  padding-inline-start: 1.875rem !important;
}

.py-30px {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.pt-30px {
  padding-top: 1.875rem !important;
}

.pb-30px {
  padding-bottom: 1.875rem !important;
}

.px-35px {
  padding-inline-start: 2.1875rem !important;
  padding-inline-end: 2.1875rem !important;
}

.pe-35px {
  padding-inline-end: 2.1875rem !important;
}

.ps-35px {
  padding-inline-start: 2.1875rem !important;
}

.py-35px {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.pt-35px {
  padding-top: 2.1875rem !important;
}

.pb-35px {
  padding-bottom: 2.1875rem !important;
}

.px-40px {
  padding-inline-start: 2.5rem !important;
  padding-inline-end: 2.5rem !important;
}

.pe-40px {
  padding-inline-end: 2.5rem !important;
}

.ps-40px {
  padding-inline-start: 2.5rem !important;
}

.py-40px {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.pt-40px {
  padding-top: 2.5rem !important;
}

.pb-40px {
  padding-bottom: 2.5rem !important;
}
// #endregion Padding Classes

// #region Margin Classes
.mx-5px {
  margin-inline-start: 0.3125rem !important;
  margin-inline-end: 0.3125rem !important;
}

.me-5px {
  margin-inline-end: 0.3125rem !important;
}

.ms-5px {
  margin-inline-start: 0.3125rem !important;
}

.my-5px {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.mt-5px {
  margin-top: 0.3125rem !important;
}

.mb-5px {
  margin-bottom: 0.3125rem !important;
}

.mx-10px {
  margin-inline-start: 0.625rem !important;
  margin-inline-end: 0.625rem !important;
}

.me-10px {
  margin-inline-end: 0.625rem !important;
}

.ms-10px {
  margin-inline-start: 0.625rem !important;
}

.my-10px {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.mt-10px {
  margin-top: 0.625rem !important;
}

.mb-10px {
  margin-bottom: 0.625rem !important;
}

.mx-15px {
  margin-inline-start: 0.9375rem !important;
  margin-inline-end: 0.9375rem !important;
}

.me-15px {
  margin-inline-end: 0.9375rem !important;
}

.ms-15px {
  margin-inline-start: 0.9375rem !important;
}

.my-15px {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.mt-15px {
  margin-top: 0.9375rem !important;
}

.mb-15px {
  margin-bottom: 0.9375rem !important;
}

.mx-20px {
  margin-inline-start: 1.25rem !important;
  margin-inline-end: 1.25rem !important;
}

.me-20px {
  margin-inline-end: 1.25rem !important;
}

.ms-20px {
  margin-inline-start: 1.25rem !important;
}

.my-20px {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.mt-20px {
  margin-top: 1.25rem !important;
}

.mb-20px {
  margin-bottom: 1.25rem !important;
}

.mx-25px {
  margin-inline-start: 1.5625rem !important;
  margin-inline-end: 1.5625rem !important;
}

.me-25px {
  margin-inline-end: 1.5625rem !important;
}

.ms-25px {
  margin-inline-start: 1.5625rem !important;
}

.my-25px {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important;
}

.mt-25px {
  margin-top: 1.5625rem !important;
}

.mb-25px {
  margin-bottom: 1.5625rem !important;
}

.mx-30px {
  margin-inline-start: 1.875rem !important;
  margin-inline-end: 1.875rem !important;
}

.me-30px {
  margin-inline-end: 1.875rem !important;
}

.ms-30px {
  margin-inline-start: 1.875rem !important;
}

.my-30px {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.mt-30px {
  margin-top: 1.875rem !important;
}

.mb-30px {
  margin-bottom: 1.875rem !important;
}

.mx-35px {
  margin-inline-start: 2.1875rem !important;
  margin-inline-end: 2.1875rem !important;
}

.me-35px {
  margin-inline-end: 2.1875rem !important;
}

.ms-35px {
  margin-inline-start: 2.1875rem !important;
}

.my-35px {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.mt-35px {
  margin-top: 2.1875rem !important;
}

.mb-35px {
  margin-bottom: 2.1875rem !important;
}

.mx-40px {
  margin-inline-start: 2.5rem !important;
  margin-inline-end: 2.5rem !important;
}

.me-40px {
  margin-inline-end: 2.5rem !important;
}

.ms-40px {
  margin-inline-start: 2.5rem !important;
}

.my-40px {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mt-40px {
  margin-top: 2.5rem !important;
}

.mb-40px {
  margin-bottom: 2.5rem !important;
}
// #endregion Margin Classes

// #region Flex Classes
.gap-10px {
  gap: 10px !important;
}

.gap-20px {
  gap: 20px !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-1 {
  flex: 1 !important;
}
// #endregion Flex Classes

// #region Line Height Classes
.line-height-0 {
  line-height: 0 !important;
}
// #endregion Line Height Classes


// #region Text Classes
.word-break-all {
  word-break: break-all !important;
}
// #endregion Text Classes


