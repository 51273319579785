@import 'src/styles/layout/variables';

.table-container {
  overflow: auto;
  border-radius: 16px;
}

table .mdc-data-table__cell,
table .mdc-data-table__header-cell {
  padding: 10px 16px;
  color: $gk-black;

  a {
    color: $gk-black;

    &:hover {
      opacity: 0.7;
    }
  }
}
