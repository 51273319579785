@import 'src/styles/layout/variables';

.error-card {
  background-color: $G-Red-light;
  border: 1px solid $G-Red-dark;
  padding: 12px;
  border-radius: 4px;

  p {
    color: var(--primaryTextColor);
    font-size: 16px;
    font-weight: 400;
  }
}

.info-card {
  background-color: $G-White;
  border-radius: 24px;
  padding: 32px 48px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1019607843);

  p {
    color: var(--primaryTextColor);
    font-size: 16px;
    font-weight: 500;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  inset-inline-start: 90%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-inline-start: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.info-icon {
  background: url(#{$assets}/icons/info-icon.svg) no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 8px;
}

.custom-menu {
  border-radius: 8px !important;

  button {
    display: flex;
  }

  .mat-mdc-menu-item {
    .mat-icon {
      color: $G-Dark-active;

      svg g path {
        fill: $G-Dark-active;
      }
    }

    .mdc-list-item__primary-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--primaryTextColor);
    }
  }
}

.chips {
  padding: 4px 8px;
  border-radius: 54px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  width: fit-content;
  &.verify {
    color: $G-Blue2;
    background-color: $G-Blue2-light-lighting;
    border: 1.5px solid #bfdffe;
  }
  &.review {
    color: $G-Green-300;
    background-color: $G-Green-light;
    border: 1.5px solid $G-Green-light-active;
  }
  &.blocked {
    color: $G-Red-normal;
    background-color: #fff9f5;
    border: 1.5px solid #ffe6d5;
  }
}

.leaflet-container {
  .leaflet-control-attribution {
    display: none;
  }
  .leaflet-pane {
    a.leaflet-popup-close-button {
      top: 4px;
      right: unset;
      left: unset;
      inset-inline-end: 4px;
      font-size: 20px;
      line-height: 20px;
    }
    .leaflet-popup {
      margin-bottom: -12px;
      margin-inline-start: 4px;
    }
    .leaflet-popup-content-wrapper {
      border-radius: 4px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
      color: $G-Dark-active;
    }
    .leaflet-popup-tip {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    }
    .leaflet-popup-content {
      p,
      a {
        font-size: 14px !important;
        line-height: 22px !important;
      }
      h1 {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
      }
      p {
        margin: 4px 0;
      }
      a {
        display: flex;
        gap: 4px;
        align-items: center;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.badge {
  border-radius: 1.5rem;
  padding: 4px 8px;
  margin: 4px 0;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 600;
  &.scheduled {
    border: 1.5px solid $G-Orange-light-active;
    background: $G-Orange-light-50;
    color: $G-Orange;
  }
  &.published {
    border: 1.5px solid $G-Green-light-active;
    background: $G-Green-light;
    color: $G-Green-300;
  }
  &.draft {
    border: 1.5px solid $G-Blue3-normal;
    background: $G-Blue2-light-lighting;
    color: $G-Blue2;
  }
}

.table-content,
mat-table {
  mat-header-row {
    mat-header-cell {
      border-bottom-color: $G-Light-Normal-hover !important;
    }
  }
  mat-row {
    mat-cell {
      border-bottom-color: $G-Light-Normal-hover !important;
    }
    mat-cell:last-child {
      mat-icon {
        color: $G-Dark-active;
      }
    }
  }
}

[dir='rtl'] .mat-calendar-header {
  .mat-calendar-controls {
    direction: rtl;
  }
}

::-webkit-scrollbar {
  width: 13px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #e8e8e8;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #bbc7db;
  cursor: pointer;
}
